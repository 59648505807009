<template>
    <div :style="{ height: 'auto', width: 'auto' }">
        <Slick
            class="favorites-slider list-inline row p-0 mb-0"
            ref="dSlick"
            :option="sliderOption"
            v-if="primaryAdvertisersAndSponsors.length > 2"
        >
            <li
                class="slide-item"
                v-for="(item, index) in primaryAdvertisersAndSponsors"
                :key="index"
            >
                <div 
                    class="primaryAdvertisingItem" 
                    :style="{ backgroundImage: `url(${item.image})` }"
                    @click="openUrl(item.advertiserURL)"
                >
                </div>
            </li>
        </Slick>
        <div 
            v-else-if="primaryAdvertisersAndSponsors.length === 1 || primaryAdvertisersAndSponsors.length === 2"
            :style="{ width: '100%', height: 'auto' }"
            class="row d-flex justify-content-center"
        >
            <div 
                v-for="(item, index) in primaryAdvertisersAndSponsors"
                :key="index"
                class="primaryAdvertisingItem" 
                :style="{ backgroundImage: `url(${item.image})`, width: '35%', }"
                @click="openUrl(item.advertiserURL)"
            >
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['primaryAdvertisersAndSponsors'],
        data(){
            return {
                sliderOption: {
                    dots: false,
                    arrows: true,
                    infinite: true,
                    speed: 1800,
                    autoplay: true,
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    prevArrow:
                        '<div class="slick-prev slick-arrow"><i class="fa fa-chevron-left"></i></div>',
                    nextArrow:
                        '<div class="slick-next slick-arrow"><i class="fa fa-chevron-right"></i></div>',
                    responsive: [
                        {
                            breakpoint: 1200,
                            settings: {
                                slidesToShow: 3,
                                slidesToScroll: 1,
                                infinite: true,
                                dots: true,
                            },
                        },
                        {
                            breakpoint: 768,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 1,
                            },
                        },
                        {
                            breakpoint: 480,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 1,
                            },
                        },
                    ],
                },
            }
        },
        methods: {
            next() {
                this.$refs.dSlick.next();
            },
            prev() {
                this.$refs.dSlick.prev();
            },
            openUrl(imageUrl) {
                const imageUrlRoute = this.$router.resolve({ path: imageUrl });
                window.open(imageUrlRoute.href.slice(1), "_blank");
            },
        },
        beforeUpdate() {
            if (this.$refs.dSlick) {
                this.$refs.dSlick.destroy();
            }
        },
        updated() {
            this.$nextTick(function () {
                if (this.$refs.dSlick) {
                    this.$refs.dSlick.create(this.sliderOption);
                }
            });
        },
    }
</script>

<style scoped>

.advertisingItemWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.primaryAdvertisingItem {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    background-color: white;
    border-radius: 30px;
    border-color: #000000;
    border-width: 4px;
    border-style: solid;
    width: 100%;

    height: 30vh;
    margin-right: 1%;
    margin-bottom: 25px;
    box-sizing: border-box;
}
/* Mobile styles */
@media (max-width: 768px) {

    .primaryAdvertisingItem {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    background-color: white;
    border-radius: 30px;
    border-color: #000000;
    border-width: 4px;
    border-style: solid;
    width: 100%;

    height: 20vh;
    margin-right: 1%;
    margin-bottom: 25px;
    box-sizing: border-box;
}

}
</style>