<template>
    <div class="container">
        <div class="row d-flex justify-content-center">
            <h3 class="col-12 title--header my-3 d-flex justify-content-center">SUPPORITNG ADVERTISERS AND SPONSORS</h3>
            <div class="col-12 mb-4">
                <primary-advertisers 
                    :primaryAdvertisersAndSponsors="primaryAdvertisersAndSponsors"
                />
                <non-primary-advertisers 
                    :nonPrimaryAdvertisersAndSponsors="nonPrimaryAdvertisersAndSponsors"
                />
            </div>
        </div>
        <div class="row d-flex justify-content-start mb-4">
            <button class="button" @click="backToAdvertiserPage">
                Back To Advertisers Page
            </button>
        </div>
    </div>
</template>

<script>
    import PrimaryAdvertisers from "./PrimaryAdvertisers";
    import NonPrimaryAdvertisers from "./NonPrimaryAdvertisers.vue";
    import router from "../../../router";

    const getAdvertiserObjectItems = (advertisers, isPrimarySponsor) => advertisers.filter((ad) => ad.isPrimarySponsor === isPrimarySponsor);

    export default{
        name: 'AdvertiseList',
        components: {
            'primary-advertisers': PrimaryAdvertisers,
            'non-primary-advertisers': NonPrimaryAdvertisers,
        },
        props: ['ads'],
        data() {
            return {
                primaryAdvertisersAndSponsors: [],
                nonPrimaryAdvertisersAndSponsors: [],
            }
        },
        methods: {
            backToAdvertiserPage() {
                router.push('/advertise');
            },
        },
        mounted() {
            this.primaryAdvertisersAndSponsors = getAdvertiserObjectItems(this.ads, true);
            this.nonPrimaryAdvertisersAndSponsors = getAdvertiserObjectItems(this.ads, false);
        },
    }
</script>

<style scoped>
.container {
    position: flex;
    overflow: hidden;
    width: 100%;
}
.button {
  background-color: #EBBA0A;
  color: black;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  margin-top: 1rem;
}

.button:hover {
  background-color: #D9A90A;
}

</style>