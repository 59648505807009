<template>
    <div class="advertisingItemWrapper d-flex flex-wrap">
        <div 
            class="nonPrimaryAdvertisingItem" 
            v-for="(item, index) in nonPrimaryAdvertisersAndSponsors" 
            :key="index"
            :style="{ backgroundImage: `url(${item.image})` }"
            @click="openUrl(item.advertiserURL)"
        >
        </div>
    </div>
</template>

<script>
    export default {
        props: ['nonPrimaryAdvertisersAndSponsors'],
        methods: {
            openUrl(imageUrl) {
                const imageUrlRoute = this.$router.resolve({ path: imageUrl });
                window.open(imageUrlRoute.href.slice(1), "_blank");
            },
        }
    }
</script>

<style scoped>

.advertisingItemWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.nonPrimaryAdvertisingItem {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    background-color: white;
    border-radius: 10px;
    width: 20%;

    height: 10vh;
    margin-right: 1%;
    margin-bottom: 25px;
    box-sizing: border-box;
}

/* Mobile styles */
@media (max-width: 768px) {

.nonPrimaryAdvertisingItem {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    background-color: white;
    border-radius: 10px;
    width: 48%;

    height: 10vh;
    margin-right: 1%;
    margin-bottom: 25px;
    box-sizing: border-box;
}

}
</style>